import 'react-toastify/dist/ReactToastify.min.css'
import 'keen-slider/keen-slider.min.css'
import '../global.css'

import { app, createStore, getHomeUrl } from '@obvio/app'
import { Popup } from '@obvio/template'
import Script from 'next/script'

import { PopupContent } from '@/components/PopupContent'
import { YTContext } from '@/components/YTContext'

export const globalStore = createStore(
  {
    NEWSLETTER: (_, newState = true) => ({
      newsletter: newState,
    }),
  },
  { newsletter: false },
)

export default app({
  globalStoreHook: globalStore,
  contextPaths: {
    /* TODO: change to ecommerce contextPath when ready. */
    ecommerce: '/sklep',
    'ecommerce.product': '/sklep/produkt',
    'ecommerce.category': '/sklep/kategoria',
    'ecommerce.search': '/sklep',
    'ecommerce.checkout': '/sklep/zakupy',
    'ecommerce.userPanel': '/sklep/panel',
    'ecommerce.userPanel.wishlist': '/sklep/panel/ulubione',
    'ecommerce.userPanel.settings': '/sklep/panel/ustawienia',
    'ecommerce.userPanel.addresses': '/sklep/panel/adresy',
    signIn: '/logowanie',
    signUp: '/rejestracja',
    privacyPolicy: '/polityka-prywatnosci',
    tos: '/regulamin',
    forgotPassword: '/zresetuj-haslo',
  },
  seoData: {
    title: 'Poziom 511 Jura Wellness Hotel & SPA',
    canonical: getHomeUrl(),
    description:
      'Poziom 511 Jura Wellness Hotel & SPA położony jest w samym sercu Jury Krakowsko-Częstochowskiej, u stóp Zamku Ogrodzieniec. Komfortowe pokoje hotelowe, basen, strefa SPA&Wellness, restauracja, sale konferencyjne i wiele więcej. Zapraszamy!',
    openGraph: {
      type: 'website',
      title: 'Poziom 511 Jura Wellness Hotel & SPA',
      description:
        'Poziom 511 Jura Wellness Hotel & SPA położony jest w samym sercu Jury Krakowsko-Częstochowskiej, u stóp Zamku Ogrodzieniec. Komfortowe pokoje hotelowe, basen, strefa SPA&Wellness, restauracja, sale konferencyjne i wiele więcej. Zapraszamy!',
      images: [
        {
          url: `https://www.poziom511.pl/static/img/poziom.jpeg`,
          width: 1200,
          height: 1200,
          type: `image/webp`,
          alt: 'Poziom',
        },
      ],
    },
  },
  enhance: (Component) => (props) =>
    (
      <YTContext>
        <Script
          src="//code.jquery.com/jquery-3.6.0.slim.min.js"
          strategy="afterInteractive"
          async
        />
        <Script
          src="//open.upperbooking.com/poziom511jurawellnesshotelspa/Booking.js?locale=pl"
          strategy="afterInteractive"
          async
        />
        <Component {...props} />
        <Popup>{(data) => <PopupContent {...data} />}</Popup>
      </YTContext>
    ),
})
