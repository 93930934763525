import { Image, mobile, styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'

import type { PopupData } from '@obvio/template'
import type { ReactElement } from 'react'

const ImageWrap = styled.div`
  width: 100%;
`
const StyledImage = styled(Image)`
  height: unset;
`

const Wrap = styled(Stack)`
  max-width: 50rem;
  width: 90vw;
  @media ${mobile} {
    width: 100%;
  }
`

export function PopupContent({
  cta,
  ctaText,
  image,
  description,
}: PopupData): ReactElement {
  return (
    <Wrap kind="vertical">
      {image ? (
        <ImageWrap>
          <StyledImage img={image} aspectRatio />
        </ImageWrap>
      ) : null}
      <div>
        <RichText value={description ?? ''} />
      </div>
      {cta ? (
        <Button kind="ghost" href={cta}>
          {ctaText ?? 'EXPLORE'}
        </Button>
      ) : null}
    </Wrap>
  )
}
